import { API } from '@stoplight/elements';
import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';

const StoplightProjectPage = () => {
  return (
    <Layout>
      <SEO title="Serply API" />

        <API
            basePath="https://docs.serply.io/api"
            apiDescriptionUrl="https://raw.githubusercontent.com/serply-inc/openapi/main/serply_openapi.yml"
            router={'memory'}
        />
    </Layout>
  );
};

export default StoplightProjectPage;
